.about-section {
  padding: 40px;
  background-color: #ffffff;
  text-align: center;
}

.about-section h2 {
  color: #a6c9cb;
  margin-bottom: 20px;
}

.about-section p {
  color: #000000;
  font-size: 1.1rem;
  line-height: 1.6;
}
