.experience-gallery {
    padding: 40px;
    background-color: #ffffff;
    text-align: center;
  }
  
  .experience-gallery h2 {
    color: #a6c9cb;
    margin-bottom: 20px;
  }
  
  .gallery-grid {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
    gap: 20px;
  }
  
  .gallery-item img {
    width: 100%;
    border-radius: 8px;
    object-fit: cover;
    height: 200px;
  }
  