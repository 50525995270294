/* Navbar.css */

/* Estilo principal da Navbar */
.navbar {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 20px;
    background-color: #a6c9cb; /* Verde claro */
    position: relative;
  }
  
  .logo-container {
    display: flex;
    align-items: center;
  }
  
  .menu-toggle {
    display: none;
    font-size: 28px;
    background: none;
    border: none;
    cursor: pointer;
    color: #000000; /* Preto */
    margin-right: auto; /* Alinha o botão à esquerda, próximo à logo */
  }
  
  /* Links da Navbar */
  .navbar-links {
    list-style: none;
    display: flex;
    gap: 20px;
  }
  
  .navbar-links li a {
    text-decoration: none;
    color: #000000; /* Preto */
    font-weight: bold;
    transition: color 0.3s ease;
  }
  
  .navbar-links li a:hover {
    color: #08265e; /* Azul escuro (ou outra cor para hover) */
  }
  
  /* Estilos responsivos para mobile */
  @media (max-width: 768px) {
    .navbar {
      flex-direction: row; /* Alinha os elementos em uma linha */
      padding: 10px 20px;
    }
  
    .menu-toggle {
      display: block; /* Mostra o botão de menu em telas menores */
      position: relative;
      left: 0; /* Posiciona o botão hamburguer à esquerda */
      margin-right: 20px; /* Adiciona um espaço entre o botão e a logo */
    }
  
    .logo-container {
      flex: 1;
      display: flex;
      justify-content: center; /* Centraliza a logo na navbar */
    }
  
    .navbar-links {
      display: none; /* Oculta os links por padrão em telas pequenas */
      flex-direction: column;
      gap: 15px;
      position: absolute;
      top: 70px; /* Coloca o menu abaixo da navbar */
      left: 20px; /* Alinha o menu à esquerda */
      background-color: #a6c9cb; /* Verde claro */
      padding: 20px;
      border-radius: 5px;
      box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1); /* Adiciona uma leve sombra para melhorar a visualização */
    }
  
    .navbar-links.open {
      display: flex; /* Mostra os links quando o menu estiver aberto */
    }
  
    .navbar-links li {
      text-align: left; /* Alinha os itens do menu à esquerda */
    }
  }
  