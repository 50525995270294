.team-section {
  padding: 50px 20px;
  background-color: #ffffff;
  text-align: center;
}

.team-section h2 {
  color: #a6c9cb;
  margin-bottom: 40px;
}

.team-members {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 20px;
}

.team-member {
  max-width: 200px;
  text-align: center;
}

.team-member img {
  width: 100%;
  border-radius: 50%;
  margin-bottom: 15px;
}

.team-member h3 {
  color: #a6c9cb;
  margin-bottom: 10px;
}

.team-member p {
  color: #000000;
  font-size: 0.9rem;
  line-height: 1.4;
}
