.service-packages {
    padding: 50px 20px;
    background-color: #f9f9f9;
  }
  
  .service-packages h2 {
    color: #a6c9cb;
    text-align: center;
    margin-bottom: 30px;
  }
  
  .packages-table {
    width: 100%;
    max-width: 800px;
    margin: 0 auto;
    border-collapse: collapse;
  }
  
  .packages-table th,
  .packages-table td {
    padding: 15px;
    text-align: center;
    border: 1px solid #a6c9cb;
  }
  
  .packages-table th {
    background-color: #a6c9cb;
    color: #ffffff;
  }
  
  .packages-table td {
    background-color: #ffffff;
    color: #000000;
  }
  