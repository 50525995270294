.blog-section {
    padding: 40px;
    background-color: #f9f9f9;
    text-align: center;
  }
  
  .blog-section h2 {
    color: #a6c9cb;
    margin-bottom: 20px;
  }
  
  .blog-posts {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 20px;
  }
  
  .blog-post {
    width: 300px;
    background-color: #ffffff;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    text-align: left;
  }
  
  .blog-post h3 {
    color: #a6c9cb;
    margin-bottom: 10px;
  }
  
  .blog-post p {
    color: #000000;
    margin-bottom: 10px;
  }
  
  .blog-post a {
    color: #a6c9cb;
    text-decoration: none;
    font-weight: bold;
  }
  
  .blog-post a:hover {
    color: #08265e;
  }
  