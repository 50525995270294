.carousel-container {
  width: 100%;
  max-width: 1200px;
  margin: 0 auto;
  padding: 20px;
  }
  
  .carousel .slide img {
  width: 100%;
  height: auto;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  }
  
  .carousel .control-arrow,
  .carousel .thumbs-wrapper,
  .carousel .carousel-status {
  display: none;
  }
  
  .carousel .control-dots .dot {
  background: #08265e;
  width: 12px;
  height: 12px;
  margin: 5px;
  border-radius: 50%;
  transition: background 0.3s ease;
  }
  
  .carousel .control-dots .dot.selected {
  background: #a6c9cb;
  }