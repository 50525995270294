.faq-section {
    padding: 50px;
    background-color: #f9f9f9;
    text-align: left;
  }
  
  .faq-section h2 {
    color: #a6c9cb;
    text-align: center;
    margin-bottom: 30px;
  }
  
  .faq-items {
    max-width: 800px;
    margin: 0 auto;
  }
  
  .faq-item {
    margin-bottom: 20px;
  }
  
  .faq-item h3 {
    color: #a6c9cb;
    font-size: 1.1rem;
  }
  
  .faq-item p {
    color: #000000;
    line-height: 1.6;
  }
  