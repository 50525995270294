.contact-form {
  max-width: 600px;
  margin: 40px auto;
  padding: 20px;
  background-color: #ffffff;
  border-radius: 8px;
  box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.1);
}

.contact-form h2 {
  color: #a6c9cb;
  margin-bottom: 20px;
  text-align: center;
}

.contact-form label {
  display: block;
  margin-bottom: 5px;
  color: #a6c9cb;
}

.contact-form input,
.contact-form textarea {
  width: 100%;
  padding: 10px;
  margin-bottom: 15px;
  border: 1px solid #a6c9cb;
  border-radius: 4px;
}

.contact-form button {
  background-color: #a6c9cb;
  color: #ffffff;
  padding: 12px 24px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 1rem;
  transition: background-color 0.3s ease;
}

.contact-form button:hover {
  background-color: #000000;
}
